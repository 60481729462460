import React from "react";
import { Trans } from "@lingui/macro";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, IconButton, Typography } from "@material-ui/core";
import { FilterList } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardError from "components/Card/CardError";
import FieldText from "components/Common/FieldText";
import DialogBox from "components/DialogBox/DialogBox";
import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { isArray } from "tools";
import { GroupGetAll, GroupDelete } from "./actions/Group.Actions";
import GroupDetail from "./part/detail/GroupDetail";
import CardFooter from "components/Card/CardFooter";
import ThirdPartyListing from "../thirdParties/ThirdPartyListing";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Add, Delete } from "@material-ui/icons";
import ThirdPartyCopernicListing from "module/tpCopernic/thirdParties/ThirdPartyListing";
import { Tab, Tabs } from "@material-ui/core";
import { TabPanel } from "components/Common/Tabs";

const GroupListing = ({ userInfo }) => {
  const [viewState, setViewState] = useState({
    isLoading: false,
    error: undefined,
    dialogBox: undefined,
    groups: [],
    selectedGroup: undefined,
    filterGroup: "",
    pageIndex: 0,
    pageSize: 10
  });
  const [filterText, setFilterText] = useState("");
  const [dialogBox, setDialogBox] = useState(null);
  const [tabIdx, setTabIdx] = useState(0);
  let isColsAudit = useMemo(() => userInfo.profiles.some(p => p.profileIdentifier.endsWith("MDM_COLS_AUDIT")), [userInfo.profiles]);

  const reloadGroups = (reset) => {
    setViewState({ ...viewState, isLoading: true });
    GroupGetAll()
      .then(groups => setViewState({ 
      ...viewState, 
      isLoading: false, 
      groups, 
      selectedGroup: reset ? undefined : viewState.selectedGroup 
      }))
      .catch(error => setViewState({ 
      ...viewState, 
      isLoading: false, 
      error 
      }));
  };

  const createGroup = parent => {
    setViewState({
      ...viewState,
      selectedGroup: {
        id: 0,
        label: "newGroup",
        countryCode: parent?.countryCode ?? userInfo?.country,
        parentId: parent?.id ?? 0,
        hasChanged: true
      }
    });
  };

  const deleteGroup = (g, confirm) => {
    if (!confirm) {
      setViewState({...viewState, dialogBox:{ type: "yesNo", message: <Trans> ConfirmDelete </Trans>, yes: () => deleteGroup(g, true) }});
    } else {
      setViewState({...viewState, dialogBox:null});
      GroupDelete(g)
        .then(() => reloadGroups(true))
        .catch(e => setViewState({ ...viewState, error: e }));
    }
  };

  useEffect(() => {
    reloadGroups(true);
  }, []);

  const filteredGroups = useMemo(() => {
    return viewState.filterGroup === ""
      ? viewState.groups
      : viewState.groups.filter(g => g.label.toLowerCase().includes(viewState.filterGroup.toLowerCase()));
  }, [viewState.groups, viewState.filterGroup]);
  const pageCount = useMemo(() => Math.ceil(filteredGroups.length / viewState.pageSize), [filteredGroups.length, viewState.pageSize]);

  var banner = <></>;
  if (viewState.error && isArray(viewState.error.response.data) && viewState.error.response.data !== undefined) {
    banner = viewState.error.response.data.map((e, idx) => (
      <Alert severity="error" key={"error_" + idx}>
        {userInfo.language === "FR" ? e.descriptionFr : e.descriptionEn}
      </Alert>
    ));
  } else if (viewState.error) return <CardError error={viewState.error} />;

  if (viewState.isLoading) return <CircularProgress />;

  return (
    <>
      {banner}
      <GridContainer>
        <GridItem xs={6}>
          <Card>
            <CardBody>
              <form
                style={{ display: "flex" }}
                onSubmit={e => {
                  e.preventDefault();
                  setViewState({ ...viewState, filterGroup: filterText, pageIndex: 0 });
                }}
              >
                <FieldText
                  label={<Trans>Filter</Trans>}
                  isEditable
                  id="filterText"
                  value={filterText}
                  onChange={e => setFilterText(e.target.value)}
                  style={{ flex: 1 }}
                />
                <IconButton onClick={() => setViewState({ ...viewState, filterGroup: filterText, pageIndex: 0, selectedGroup: undefined })}>
                  <FilterList />
                </IconButton>
              </form>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              {filteredGroups
                .slice(viewState.pageIndex * viewState.pageSize, viewState.pageIndex * viewState.pageSize + viewState.pageSize)
                .map((group, idx) => (
                  <div
                    key={idx}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      backgroundColor: viewState.selectedGroup?.id === group.id ? "yellow" : "initial"
                    }}
                  >
                    <Button onClick={() => setViewState({ ...viewState, selectedGroup: group })}>{group.label}</Button>
                    <Typography variant="caption" color="initial" fontSize="small">
                      {group.thirdPartyCount}
                      {createGroup && (
                        <IconButton
                          onClick={e => {
                            e.stopPropagation();
                            createGroup(group);
                          }}
                        >
                          <Add fontSize="small" />
                        </IconButton>
                      )}
                      {group.id > 0 && !filteredGroups.some(s => s.parentId === group.id) && (
                        <IconButton
                          onClick={e => {
                            e.stopPropagation();
                            group.id === 0 ? setViewState({ ...viewState, selectedGroup: null }) : deleteGroup(group, false);
                          }}
                        >
                          <Delete fontSize="small" />
                        </IconButton>
                      )}
                    </Typography>
                  </div>
                ))}
            </CardBody>
            <CardFooter style={{ display: "flex", justifyItems: "center" }}>
              {viewState.pageIndex > 0 ? (
                <Button onClick={() => setViewState({ ...viewState, pageIndex: viewState.pageIndex - 1 })}>
                  <Trans>Previous</Trans>
                </Button>
              ) : (
                <span></span>
              )}
              <Typography variant="subtitle1" style={{ marginLeft: "5px", marginRight: "5px" }}>
                {viewState.pageIndex + 1} / {pageCount}
              </Typography>
              {viewState.pageIndex + 1 < pageCount ? (
                <Button variant="outlined" onClick={() => setViewState({ ...viewState, pageIndex: viewState.pageIndex + 1 })}>
                  <Trans>Next</Trans>
                </Button>
              ) : (
                <span></span>
              )}
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={6}>
          <GroupDetail
            group={viewState.selectedGroup}
            setGroup={g => {
              setViewState({ ...viewState, selectedGroup: g });
            }}
            reloadGroups={reloadGroups}
            isGroupEditable={!isColsAudit}
          />
        </GridItem>
        {viewState.selectedGroup && (
          <GridItem xs={12}>
            <Tabs variant="scrollable" value={tabIdx} onChange={(e, value) => setTabIdx(value)} aria-label="Tabs supplier">
              <Tab label="Columbus" key="ColumbusTpTab" />
              <Tab label="Copernic" key="CopernicTab"/>
            </Tabs>

            <TabPanel value={tabIdx} index={0}>
              <ThirdPartyListing groupId={viewState.selectedGroup?.id} />
            </TabPanel>
            <TabPanel value={tabIdx} index={1}>
              <ThirdPartyCopernicListing groupId={viewState.selectedGroup?.id} />
            </TabPanel>
          </GridItem>
        )}
      </GridContainer>
      {viewState.dialogBox && <DialogBox dialogBox={viewState.dialogBox} setDialogBox={s => setViewState({ ...viewState, dialogBox: s })} />}
    </>
  );
};

const mapStateToProps = state => {
  return {
    userInfo: state.AuthenticationReducer?.user
  };
};

export default connect(mapStateToProps)(GroupListing);
