import React, { useEffect, useState } from "react";
import { GetUsersByProfileTerritories } from "services/user/UserHelper";

import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import "assets/scss/components/ThirdPartyResult.css";
import "assets/scss/components/ThirdPartySearchCriteria.css";
import CardError from "components/Card/CardError";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import DialogBox from "components/DialogBox/DialogBox";
import Button from "components/CustomButtons/Button";
import { Chip, CircularProgress } from "@material-ui/core";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";

import { Trans } from "@lingui/macro";
import SiteDetailContent from "../../../site/containers/SiteDetailContent";
import { Alert, AlertTitle } from "@material-ui/lab";
import { connect } from "react-redux";
import { OpenStructure } from "module/structure/store/actions";
import { SiteGetDetailed } from "../../../site/actions/SiteActions";
import { HasRight } from "services/user/UserHelper";

function LocationCrupdate({ workflow, openStructure, closeWorkflow, validateWorkflow, abortWorkflow, classes, defaultLang }) {
  const [state, setState] = useState({ isLoading: true });
  const [dialogBox, setDialogBox] = useState(null);

  const loadLocation = () => {
    setState({ isLoading: true });
    SiteGetDetailed(
      workflow.workflowLocation.locationId,
      siteResult => {
        setState({ ...state, isLoading: false, site: siteResult });
      },
      e => setState({ isLoading: false, error: e })
    );
  };

  useEffect(() => {
    loadLocation();
  }, [workflow]);


  var alert = <></>;
  if (workflow.workflowStatusCode === "Pending") {
    alert = (
      <Alert severity="info">
        <AlertTitle>
          <Trans>Workflow_Status_Pending</Trans>
        </AlertTitle>
      </Alert>
    );
  } else if (workflow.workflowStatusCode === "OnError") {
    alert = (
      <Alert severity="error">
        <AlertTitle>
          <Trans>Workflow_Status_Error</Trans>
        </AlertTitle>
      </Alert>
    );
  } else if (workflow.workflowStatusCode === "Aborted") {
    alert = (
      <Alert severity="warning">
        <AlertTitle>
          <Trans>Workflow_Status_Aborted</Trans>
        </AlertTitle>
      </Alert>
    );
  } else if (workflow.workflowStatusCode === "Finished") {
    alert = (
      <Alert severity="success">
        <AlertTitle>
          <Trans>Workflow_Status_Finished</Trans>
        </AlertTitle>
      </Alert>
    );
  }

  const closeDetail = isFromButton => {
    if (!isFromButton) {
      setDialogBox({ type: "yesNo", message: <Trans> ConfirmCloseWithoutSave </Trans>, yes: () => closeWorkflow() });
    } else {
      closeWorkflow();
    }
  };

  const validate = () => {
    setDialogBox({
      canDismiss: false,
      type: "okCancel",
      textbox: { title: <Trans>Comment</Trans>, rows: 2 },
      ok: text => {
        validateWorkflow({ ...workflow, validatorComment: text });
        setDialogBox(null);
      }
    });
  };

  const abort = () => {
    setDialogBox({
      canDismiss: false,
      type: "okCancel",
      textbox: { title: <Trans>Comment</Trans>, rows: 2 },
      ok: text => {
        abortWorkflow({ ...workflow, validatorComment: text });
        setDialogBox(null);
      }
    });
  };

  let dialogContent;
  if (state.error) dialogContent = <CardError error={state.error} />;
  else if (state.isLoading) dialogContent = <CircularProgress />;
  else if (!state.site) dialogContent = <CardError error={{ message: "Associated location is not found." }} />;
  else {
    dialogContent =
    <DialogContent
      style={{
        ...DialogInlineStyleDetail.dialogContent
      }}
      dividers={true}
    >
      <div className="divContainer">
        {alert}
        {workflow.workflowStatusCode === "Pending" && <CardValidators workflow={workflow} />}
        <CardLocation workflow={workflow} openStructure={openStructure} site={state.site} defaultLang={defaultLang} />
      </div>
    </DialogContent>
  }

  const dialogActions = (
    <DialogActions>
      {workflow.workflowStatusCode === "Pending" && HasRight("site.approve") && (
        <Button color="success" onClick={validate}>
          <Trans>Validate</Trans>
        </Button>
      )}
      {workflow.workflowStatusCode === "Pending" && HasRight("site.approve") && (
        <Button color="danger" onClick={abort}>
          <Trans>WF_Abort</Trans>
        </Button>
      )}
      <div style={{ flex: "1 0 0" }} />
      <Button onClick={() => closeDetail(true)} color={!state.hasChanged ? "info" : ""}>
        <Trans> Close </Trans>
      </Button>
    </DialogActions>
  );


  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={Boolean(workflow)}
        onClose={() => closeDetail(false)}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogPaper }}
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          <div>{workflow.label}</div>
        </DialogTitle>
        {dialogContent}
        {dialogActions}
      </Dialog>
      <DialogBox dialogBox={dialogBox} setDialogBox={setDialogBox} />
    </>
  );
}

function CardValidators({ workflow }) {
  var [state, setState] = useState({ isLoading: false });
  if (!state.isLoading && !state.validators) {
    setState({ isLoading: true });
    GetUsersByProfileTerritories(
      workflow.validatorProfileCodes,
      workflow.validatorTerritoryCountryCode,
      workflow.validatorTerritoryErtId,
      v => setState({ isLoading: false, error: null, validators: v }),
      e => setState({ isLoading: false, error: e, validators: [] })
    );
  }

  if (state.error) {
    return <CardError error={state.error} />;
  }

  var body = "";
  if (state.isLoading) {
    body = <CircularProgress />;
  } else if (state.validators) {
    body = state.validators.map((v, k) => <Chip key={k} label={v.firstName + " " + v.lastName + " (" + v.id + ")"} />);
  }

  return (
    <Card>
      <CardBody>
        <div>
          <strong>
            <Trans>Workflow_WaitingForValidationFrom</Trans>
          </strong>
          {body}
        </div>
        <div>
          <strong>
            <Trans> WF_AuthorComment : </Trans>
          </strong>
          {workflow.creationComment}
        </div>
      </CardBody>
    </Card>
  );
}

function CardLocation({ site, openStructure, defaultLang }) {
  if (!site) 
    return <CardError error={{ message: "Associated location is not found." }} />;
  else
    return <SiteDetailContent isEditable={false} site={site} openStructure={openStructure} defaultLang={defaultLang} />;
}

const DialogInlineStyleDetail = {
  dialogContent: {
    padding: "0px 10px 0px",
    height: "95%"
  },
  dialogPaper: {
    minHeight: "90vh",
    maxHeight: "90vh"
  }
};

const mapStateToProps = state => {
  return {
    defaultLang: state.AuthenticationReducer.user.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openStructure: (structureType, identifiant) => {
      dispatch(OpenStructure(structureType, identifiant));
    }
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(LocationCrupdate));
