import React, { useState } from "react";
import uuidv4 from "uuid/v4"; // Generate GUID
import { isNull, isNullOrEmpty } from "../../../tools";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Button, FormLabel, IconButton, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { Add, AttachMoneyRounded, BusinessRounded, Delete, Edit, Email, Person, Phone, Print, SaveAltOutlined } from "@material-ui/icons";

//lingui
import { Trans } from "@lingui/macro";
import { isArrayNullOrEmpty } from "tools";
import GetMasterValueLabel from "components/Common/MasterValueLabel";

function ContactCard({
  contact,
  contactTypes,
  lockDetailTypes,
  requiredDetailTypeCodes,
  requiredDetailTitle,
  isCreation,
  isEditable,
  isDeletable,
  isSwitchable,
  customHeader,
  countryPhoneCode,
  errorCodes,
  onContactSave,
  onContactChange,
  onContactDelete,
  defaultLang
}) {
  if (isNull(requiredDetailTitle)) {
    requiredDetailTitle = true;
  }

  if (isNull(contact)) {
    contact = {
      firstName: "",
      lastName: "",
      details: []
    };
  }

  if (!isArrayNullOrEmpty(requiredDetailTypeCodes)) {
    requiredDetailTypeCodes.map(item => {
      if (!contact.details.some(d => d.contactDetailTypeCode === item)) {
        contact.details = [...contact.details, { contactDetailTypeCode: item }];
      }
    });
  }

  if (!requiredDetailTitle) {
    contact.details = contact.details.map(d => {
      return { ...d, title: "Principal", phoneCode: isNullOrEmpty(countryPhoneCode) ? "" : "+" + countryPhoneCode };
    });
  }

  var content, actions;
  var [isEdit, setIsEdit] = useState(isCreation || (!isNull(isSwitchable) && !isSwitchable && isEditable));
  var [controlErrors, setControlErrors] = useState([]);
  var [firstName, setFirstName] = useState(contact.firstName);
  var [lastName, setLastName] = useState(contact.lastName);
  var [contactTypeCode, setContactTypeCode] = useState(!isArrayNullOrEmpty(contactTypes) ? contact.contactTypeCode : "");
  var [details, setDetails] = useState(
    contact.details.map(d => {
      d.localId = uuidv4();
      return d;
    })
  );
  var [insertValidated, setInsertValidated] = useState(false);

  function cancelEdit() {
    if (isCreation && !insertValidated) {
      if (!isNull(onContactDelete)) {
        onContactDelete(contact, isCreation);
      }
    } else {
      setFirstName(contact.firstName);
      setLastName(contact.lastName);
      setContactTypeCode(contact.contactTypeCode);
      setIsEdit(false);
    }
  }

  function controlEdit() {
    var errors = [];

    details.map(detail => {
      if (detail.contactDetailTypeCode === "MAIL") {
        var re = /\S+@\S+\.\S+/;
        if (!re.test(detail.value)) {
          errors.push("Mail " + detail.value + " is not in valid format");
        }
      } else {
        var re = /\d+/;
        if (!re.test(detail.value)) {
          errors.push(detail.contactDetailTypeCode + " number " + detail.value + " is not in valid format");
        }
      }
    });

    setControlErrors(errors);
    return isArrayNullOrEmpty(errors);
  }

  function validEdit() {
    if (controlEdit()) {
      onContactSave({
        ...contact,
        firstName,
        lastName,
        contactTypeCode,
        details
      });
      setInsertValidated(true);
      setIsEdit(false);
    }
  }

  function addContactDetail() {
    setDetails([
      ...details,
      {
        localId: uuidv4(),
        contactDetailTypeCode: "MAIL"
      }
    ]);
  }

  function handleChange(field, value) {
    var newContact = contact;

    if (field === "firstName") {
      newContact.firstName = value;
      setFirstName(value);
    } else if (field === "lastName") {
      newContact.lastName = value;
      setLastName(value);
    } else if (field === "detail") {
      var newDetails = details.map(d => {
        return d.localId !== value.localId ? d : value;
      });
      newContact.details = newDetails;
      setDetails(newDetails);
    }

    if (!isNullOrEmpty(isSwitchable) && !isSwitchable && !isNull(onContactChange)) {
      onContactChange(newContact);
    }
  }

  function handleDetailDelete(detailId) {
    setDetails(details.filter(d => d.localId !== detailId));
  }

  var contactTypeRow = "";
  if (!isArrayNullOrEmpty(contactTypes)) {
    contactTypeRow = (
      <GridContainer>
        <GridItem xs={4} sm={4} md={4}>
          <FormLabel>
            <Trans>ContactType</Trans>
          </FormLabel>
        </GridItem>
        <GridItem xs={8} sm={8} md={8}>
          <Select
            name="contactTypeCode"
            value={contactTypeCode}
            inputProps={{
              name: "contactTypeCode",
              id: "contactTypeCode"
            }}
            onChange={e => setContactTypeCode(e.target.value)}
          >
            {contactTypes.map((contactType, idx) => {
              var contactTypeIcon = GetContactTypeIcon(contactType.code);
              return (
                <MenuItem value={contactType.code} key={idx}>
                  {contactTypeIcon} {GetMasterValueLabel(contactType, defaultLang)}
                </MenuItem>
              );
            })}
          </Select>
        </GridItem>
      </GridContainer>
    );
  }

  if (isEdit) {
    var detailsAdd = "";
    if (isNull(lockDetailTypes) || !lockDetailTypes) {
      detailsAdd = (
        <IconButton>
          <Add onClick={() => addContactDetail()} />
        </IconButton>
      );
    }
    content = (
      <div>
        <GridContainer>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>First name</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <TextField
              margin="dense"
              id="firstName"
              type="text"
              fullWidth
              value={firstName}
              onChange={e => handleChange("firstName", e.target.value)}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Last name</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <TextField margin="dense" id="lastName" type="text" fullWidth value={lastName} onChange={e => handleChange("lastName", e.target.value)} />
          </GridItem>
        </GridContainer>
        {contactTypeRow}
        <div>
          {details.map((detail, key) => (
            <ThirdPartyContactDetailCard
              key={key}
              isEdit={true}
              handleChange={d => handleChange("detail", d)}
              handleDelete={handleDetailDelete}
              isLocked={!isNull(lockDetailTypes) && lockDetailTypes}
              detail={detail}
              countryPhoneCode={countryPhoneCode}
              requiredDetailTitle={requiredDetailTitle}
              errorCodes={errorCodes}
            />
          ))}
          {detailsAdd}
          <div style={{ color: "red" }}>
            {controlErrors.map(error => (
              <div>{error}</div>
            ))}
          </div>
        </div>
      </div>
    );
    actions =
      isNullOrEmpty(isSwitchable) || isSwitchable ? (
        <div>
          <Button size="small" onClick={() => validEdit()}>
            Valider
          </Button>
          <Button size="small" onClick={() => cancelEdit()}>
            Annuler
          </Button>
        </div>
      ) : (
        ""
      );
  } else {
    var btnDelete = isDeletable ? (
      <Button onClick={() => onContactDelete(contact, isCreation)} size="small" color="danger">
        <Delete />
        Supprimer
      </Button>
    ) : (
      ""
    );
    content = (
      <div>
        {!isNullOrEmpty(customHeader) ? (
          <span>
            {firstName} {lastName}
          </span>
        ) : (
          ""
        )}
        <Typography variant="body2" color="textSecondary">
          {details.map((detail, key) => (
            <ThirdPartyContactDetailCard key={key} isEdit={false} detail={detail} countryPhoneCode={countryPhoneCode} errorCodes={errorCodes} />
          ))}
        </Typography>
      </div>
    );
    actions =
      isNullOrEmpty(isSwitchable) || isSwitchable ? (
        isEditable ? (
          <div>
            <Button size="small" onClick={() => setIsEdit(true)}>
              <Edit />
              Modifier
            </Button>
            {btnDelete}
          </div>
        ) : (
          <div />
        )
      ) : (
        ""
      );
  }

  var cardHeaderText = isNullOrEmpty(customHeader) ? (
    <span>
      {GetContactTypeIcon(contactTypeCode)} {firstName} {lastName}
    </span>
  ) : (
    <span>{customHeader}</span>
  );
  return (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <h4>{cardHeaderText}</h4>
      </CardHeader>
      <CardBody>{content}</CardBody>
      <CardFooter>{actions}</CardFooter>
    </Card>
  );
}

function ThirdPartyContactDetailCard({ detail, isEdit, isLocked, requiredDetailTitle, countryPhoneCode, errorCodes, handleChange, handleDelete }) {
  if (isEdit) {
    var firstCell = "";
    if (!isNull(isLocked) && isLocked) {
      switch (detail.contactDetailTypeCode) {
        case "MAIL":
          firstCell = <Email />;
          break;
        case "PHONE":
          firstCell = <Phone />;
          break;
        case "FAX":
          firstCell = <Print />;
          break;
      }
    } else {
      firstCell = (
        <Select
          name="contactDetailTypeCode"
          value={detail.contactDetailTypeCode}
          inputProps={{
            name: "contactDetailTypeCode",
            id: "contactDetailTypeCode"
          }}
          onChange={e =>
            handleChange({
              ...detail,
              contactDetailTypeCode: e.target.value
            })
          }
        >
          <MenuItem value="MAIL">
            <Email />
          </MenuItem>
          <MenuItem value="PHONE">
            <Phone />
          </MenuItem>
          <MenuItem value="FAX">
            <Print />
          </MenuItem>
        </Select>
      );
    }
    firstCell = (
      <GridItem xs={2} sm={2} md={2}>
        {firstCell}
      </GridItem>
    );

    var widthFieldValue = 10;
    var detailTitle = "";
    if (requiredDetailTitle) {
      widthFieldValue -= 4;
      detailTitle = (
        <GridItem xs={4} sm={4} md={4}>
          <TextField
            name="value"
            value={detail.title}
            inputProps={{
              name: "title",
              id: "title"
            }}
            onChange={e => handleChange({ ...detail, title: e.target.value })}
            placeholder="Title"
          />
        </GridItem>
      );
    }

    var btnActions = "";
    if (isNull(isLocked) || !isLocked) {
      widthFieldValue -= 2;
      btnActions = (
        <GridItem xs={2} sm={2} md={2}>
          <IconButton onClick={() => handleDelete(detail.localId)}>
            <Delete />
          </IconButton>
        </GridItem>
      );
    }

    const error = code => errorCodes && errorCodes.some(e => e.code === code);
    var valueField = (
      <TextField
        name="value"
        value={detail.value}
        inputProps={{
          name: "value",
          id: "value"
        }}
        onChange={e => handleChange({ ...detail, value: e.target.value })}
        error={error(detail.contactDetailTypeCode)}
        fullWidth
      />
    );
    var value = "";
    if (detail.contactDetailTypeCode === "PHONE" || detail.contactDetailTypeCode === "FAX") {
      value = (
        <div style={{ display: "flex" }}>
          <div style={{ width: "33%" }}>
            <TextField
              name="value"
              value={isNullOrEmpty(detail.phoneCode) ? "+" + countryPhoneCode : detail.phoneCode}
              inputProps={{
                name: "value",
                id: "value"
              }}
              onChange={e => handleChange({ ...detail, phoneCode: e.target.value })}
            />
          </div>
          <div style={{ width: "67%" }}>{valueField}</div>
        </div>
      );
    } else {
      value = valueField;
    }

    value = (
      <GridItem xs={widthFieldValue} sm={widthFieldValue} md={widthFieldValue}>
        {value}
      </GridItem>
    );

    return (
      <GridContainer>
        {firstCell}
        {detailTitle}
        {value}
        {btnActions}
      </GridContainer>
    );
  } else {
    var icon;
    var value;
    var textToDisplay = detail.value;
    if (!isNullOrEmpty(detail.title)) textToDisplay = detail.title + " - " + textToDisplay;
    if (detail.contactDetailTypeCode === "MAIL") {
      icon = <Email />;
      value = <a href={"mailto://" + detail.value}>{textToDisplay}</a>;
    } else if (detail.contactDetailTypeCode === "PHONE") {
      icon = <Phone />;
      value = <a href={"tel://" + detail.value}>{textToDisplay}</a>;
    } else {
      icon = <Print />;
      value = <span>{textToDisplay}</span>;
    }

    return (
      <GridContainer>
        <GridItem xs={2} sm={2} md={2}>
          {icon}
        </GridItem>
        <GridItem xs={10} sm={10} md={10}>
          {value}
        </GridItem>
      </GridContainer>
    );
  }
}

function GetContactTypeIcon(contactTypeCode) {
  switch (contactTypeCode) {
    case "GENERAL":
      return <BusinessRounded />;
    case "COMPTA":
      return <AttachMoneyRounded />;
    case "REPO_EBX":
      return <SaveAltOutlined />;
  }
  return <Person />;
}

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px",
    color: "rgba(255,255,255,1)"
  }
};

export default ContactCard;
