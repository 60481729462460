import { Trans } from "@lingui/macro";
import { Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { SearchOutlined, ViewDay, ViewHeadline, ViewQuilt } from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import LocalStorageHelper from "services/common/LocalStorageHelper";
import { TpPaymentCharacteristicStatus } from "store/MasterValue/MasterValueTypes";
import { isNull } from "tools";
import { CleanSearchPaymentCharacteristics, SearchPaymentCharacteristics } from "../store/actions/PaymentCharacteristics.Actions";
import { CleanSearchThirdParties } from "../store/actions/ThirdParties.Actions";

function PaymentCharacteristicSearch({ showSearchCriteriaOnSideBar, searchCriteriasCtx, classes }) {
  var dispatch = useDispatch();

  var [criteria, setCriteria] = useState(isNull(searchCriteriasCtx) ? {} : searchCriteriasCtx.criterias);
  var [searchMode, setSearchMode] = useState(isNull(searchCriteriasCtx) ? "Simple" : searchCriteriasCtx.searchMode);
  var searchScope = isNull(searchCriteriasCtx) ? "paymentCharacteristics" : searchCriteriasCtx.searchScope;

  useEffect(() => {
    if (searchScope !== "paymentCharacteristics") dispatch(CleanSearchPaymentCharacteristics());
  });

  var searchGridSize = 4;
  if (showSearchCriteriaOnSideBar) searchGridSize = 12;

  const blockHeaderSearchClasses = ["blockHeaderSearch"];
  if (showSearchCriteriaOnSideBar) blockHeaderSearchClasses.push("Reduce");

  const simpleSearchClasses = ["simple-search"];
  const extendedSearchClasses = ["extended-search"];
  const complexSearchClasses = ["complex-search"];
  var searchTitle = "";

  switch (searchMode) {
    case "Simple":
      simpleSearchClasses.push("show");
      if (!showSearchCriteriaOnSideBar) searchTitle = <Trans> Quick Search </Trans>;
      break;
    case "Extend":
      extendedSearchClasses.push("show");
      if (!showSearchCriteriaOnSideBar) searchTitle = <Trans> Extended Search</Trans>;
      break;
    case "Complex":
      complexSearchClasses.push("show");
      if (!showSearchCriteriaOnSideBar) searchTitle = <Trans> Complex Search</Trans>;
      break;
    default:
      console.warn("Unknown searchMode: " + searchMode);
      break;
  }

  var launchSearch = e => {
    e.preventDefault();

    var localStorageHelper = new LocalStorageHelper();
    var getPref = localStorageHelper.get("Preferences");
    var pageSize = 10;
    if (getPref !== null) {
      pageSize = getPref.Pref.ResultPageSize * 1;
    }

    setCriteria({
      ...criteria,
      startPage: 0,
      pageSize: pageSize,
      orderProperty: null,
      isDescendingOrder: false
    });
    dispatch(SearchPaymentCharacteristics(criteria, searchMode));
  };

  return (
    <Card>
      <CardHeader color="info" icon>
        <CardIcon color="info">
          <SearchOutlined />
        </CardIcon>
        <div className={blockHeaderSearchClasses.join(" ")}>
          <div className="blockHeaderSearchItem">
            <ToggleButtonGroup value={searchMode} exclusive onChange={(e, mode) => setSearchMode(mode)}>
              <ToggleButton value="Simple">
                <ViewDay />
              </ToggleButton>
              <ToggleButton value="Extend">
                <ViewHeadline />
              </ToggleButton>
              <ToggleButton value="Complex">
                <ViewQuilt />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div className="blockHeaderSearchItem">
            <Typography ariant="display1">{searchTitle}</Typography>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <div className={simpleSearchClasses.join(" ")}>
          <form onSubmit={launchSearch} autoComplete="off">
            <GridContainer>
              <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
                <CustomInput
                  labelText={<Trans>IBAN</Trans>}
                  id="criteriaIbanSearch"
                  onChange={e => setCriteria({ ...criteria, criteriaIban: e.target.value })}
                  value={criteria.criteriaIban}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
                <MasterValueAutocomplete
                  mvType={TpPaymentCharacteristicStatus}
                  selectedValue={criteria.criteriaStatus}
                  onValueChange={value => setCriteria({ ...criteria, criteriaStatus: value })}
                  label={<Trans>StatusCode</Trans>}
                  isEditable
                />
              </GridItem>
            </GridContainer>
            <Button type="submit" color="info" className={classes.registerButton} onClick={launchSearch}>
              <Trans> Search </Trans>
            </Button>
            <Button
              className={classes.registerButton}
              onClick={e => {
                e.preventDefault();
                dispatch(CleanSearchThirdParties());
              }}
            >
              <Trans> Clean </Trans>
            </Button>
          </form>
        </div>
      </CardBody>
    </Card>
  );
}

const mapStateToProps = state => {
  return {
    searchCriteriasCtx: state.TP_Search
  };
};

export default connect(mapStateToProps)(withStyles(thirdPartySearchCriteriaStyle)(PaymentCharacteristicSearch));
