import React from "react";
import { Trans } from "@lingui/macro";
import { FormLabel, TextField } from "@material-ui/core";
import { Email, Phone } from "@material-ui/icons";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { isArrayNullOrEmpty } from "../../../tools";

const filter = createFilterOptions();

const ComponentCoupaContact = ({ isEditable, contact, setContact, errors, customHeader }) => {
  let content = <></>;

  if (isEditable) {
    content = (
      <div>
        <GridContainer>
          <GridItem xs={3} sm={3} md={3}>
            <FormLabel>
              <Trans>First name</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={9} sm={9} md={9}>
            <TextField
              margin="dense"
              id="firstName"
              type="text"
              fullWidth
              value={contact.firstName}
              onChange={e => setContact({ ...contact, firstName: e.target.value })}
              error={errors.some(e => e.code === "FirstName")}
            />
          </GridItem>
          <GridItem xs={3} sm={3} md={3}>
            <FormLabel>
              <Trans>Last name</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={9} sm={9} md={9}>
            <TextField
              margin="dense"
              id="lastName"
              type="text"
              fullWidth
              value={contact.lastName}
              onChange={e => setContact({ ...contact, lastName: e.target.value })}
              error={errors.some(e => e.code === "LastName")}
            />
          </GridItem>
          <GridItem xs={3} sm={3} md={3}>
            <FormLabel>
              <Email />
            </FormLabel>
          </GridItem>
          <GridItem xs={9} sm={9} md={9}>
            <Autocomplete
              value={
                isArrayNullOrEmpty(contact.mails)
                  ? []
                  : contact.mails.map(v => {
                      return { inputValue: v };
                    })
              }
              disabled={!isEditable}
              id="contactMails"
              onChange={(e, newValue) => setContact({ ...contact, mails: newValue.map(v => v.inputValue) })}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== "") {
                  filtered.push({
                    inputValue: params.inputValue,
                    title: `Add "${params.inputValue}"`
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              multiple
              getOptionLabel={option => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option;
              }}
              options={[]}
              renderOption={option => option.title}
              renderInput={params => <TextField {...params} error={errors.some(e => e.code === "Mail")} variant="standard" fullWidth />}
            />
          </GridItem>
          <GridItem xs={3} sm={3} md={3}>
            <FormLabel>
              <Phone />
            </FormLabel>
          </GridItem>
          <GridItem xs={9} sm={9} md={9}>
            <div style={{ display: "flex" }}>
              <TextField
                margin="dense"
                id="contactPhoneCode"
                type="text"
                value={contact.phoneCode}
                onChange={e => setContact({ ...contact, phoneCode: e.target.value })}
                style={{ width: "20%" }}
                error={errors.some(e => e.code === "Phone")}
              />
              <TextField
                margin="dense"
                id="contactPhoneNumber"
                type="text"
                value={contact.phoneNumber}
                onChange={e => setContact({ ...contact, phoneNumber: e.target.value })}
                style={{ width: "80%" }}
                error={errors.some(e => e.code === "Phone")}
              />
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  } else {
    content = (
      <div>
        <div>
          {contact.firstName} {contact.lastName}
        </div>
      </div>
    );
  }

  return (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <h4>{customHeader}</h4>
      </CardHeader>
      <CardBody>{content}</CardBody>
    </Card>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

export default ComponentCoupaContact;
