import { combineReducers } from "redux";
import AuthenticationReducer from "./Authentication/Reducer";
import MasterValueReducer from "./MasterValue/MasterValueReducer";
import HistoryReducer from "./History/HistoryReducer";
import HubbleParamsReducer from "./HubbleParams/HubbleParamsReducer";
import TP_SearchReducer from "../module/tpCopernic/store/reducers/TP_SearchReducer";
import TP_ThirdPartiesReducer from "../module/tpCopernic/store/reducers/TP_ThirdPartiesReducer";
import TP_PaymentCharacteristicsReducer from "../module/tpCopernic/store/reducers/TP_PaymentCharacteristicsReducer";
import TP_DunsMonitoringReducer from "../module/tpCopernic/store/reducers/TP_DunsMonitoringReducer";
import TP_CodeMigReducer from "../module/tpCopernic/store/reducers/TP_CodeMigReducer";
import ST_SearchReducer from "../module/site/store/reducers/ST_SearchReducer";
import ST_ColCodeReducer from "../module/structure/store/reducers/ST_ColCodeReducer";
import AMC_Reducer from "module/amc/store/reducers/AMC_Reducer";
import Admin_Reducer from "module/admin/store/reducers/Admin_Reducer";
import ModuleReducer from "./Modules/ModuleReducer";
import UR_ProfileDelegationReducer from "../module/profileDelegation/store/UR_ProfileDelegationReducer";

export default combineReducers({
  AuthenticationReducer,
  MasterValueReducer,
  HistoryReducer,
  TP_Search: TP_SearchReducer,
  TP_ThirdParties: TP_ThirdPartiesReducer,
  TP_PaymentCharacteristics: TP_PaymentCharacteristicsReducer,
  TP_DunsMonitoring: TP_DunsMonitoringReducer,
  TP_MigCode: TP_CodeMigReducer,
  ST_Search: ST_SearchReducer,
  ST_ColCode: ST_ColCodeReducer,
  AMC_Reducer: AMC_Reducer,
  Admin_Reducer: Admin_Reducer,
  ModuleReducer: ModuleReducer,
  UR_ProfileDelegation: UR_ProfileDelegationReducer,
  HubbleParamReducer: HubbleParamsReducer
});
