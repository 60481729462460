import { Trans } from "@lingui/macro";
import { Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormLabel, TextField, Tooltip } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { History } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import "assets/scss/components/ThirdPartyResult.css";
import "assets/scss/components/ThirdPartySearchCriteria.css";
import CardBody from "components/Card/CardBody";
import CardError from "components/Card/CardError";
import CardHeader from "components/Card/CardHeader";
import { ApimGetPromise } from "components/Common/ApimSender";
import Button from "components/CustomButtons/Button";
import DialogBox from "components/DialogBox/DialogBox";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import * as ThirdPartyActions from "module/tpCopernic/store/actions";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { HasOneOfRights, HasRight } from "services/user/UserHelper";
import WorkflowHistory from "../WorkflowHistory";
import FieldText from "components/Common/FieldText";

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

function InfolegaleMerge({ workflow, validateWorkflow, abortWorkflow, closeWorkflow, errors, masterValues, currentUser, classes }) {
  const [state, setState] = useState({ workflow: workflow });
  const [dialogBox, setDialogBox] = useState(null);
  var [openHisto, setOpenHisto] = useState(false);
  const dispatch = useDispatch();
  const request = useMemo(() => state.workflow.workflowInfolegaleMerge, [state.workflow]);

  const defaultLang = useMemo(() => currentUser.language, [currentUser]);

  const closeDetail = isFromButton => {
    if (!isFromButton) {
      setDialogBox({ type: "yesNo", message: <Trans> ConfirmCloseWithoutSave </Trans>, yes: () => closeWorkflow() });
    } else {
      closeWorkflow();
    }
  };

  const validate = () => {
    setDialogBox({
      canDismiss: false,
      type: "okCancel",
      textbox: { title: <Trans>Comment</Trans>, rows: 2 },
      ok: text => {
        validateWorkflow({ ...state.workflow, comment: text });
        setDialogBox(null);
      }
    });
  };

  const abort = () => {
    setDialogBox({
      canDismiss: false,
      type: "okCancel",
      textbox: { title: <Trans>Comment</Trans>, rows: 2 },
      ok: text => {
        abortWorkflow({ ...state.workflow, comment: text });
        setDialogBox(null);
      }
    });
  };

  // on first load, set the supplierRequest (will recalculate the coupaContentGroup and validators if needed)
  useEffect(() => {
    if (workflow?.workflowInfolegaleMerge) {
      const infolegaleMerge = workflow.workflowInfolegaleMerge;
      ApimGetPromise("api/TpCopernic/ThirdParties/" + infolegaleMerge.oldThirdPartyId)
        .then(oldTp => {
          ApimGetPromise("api/TpCopernic/ThirdParties/" + infolegaleMerge.newThirdPartyId)
            .then(newTp => {
              setState({
                ...state,
                isLoading: false,
                workflow: workflow,
                exitingThirdParty: oldTp.thirdParty,
                newThirdParty: newTp.thirdParty
              });
            })
            .catch(e => {
              setState({ ...state, isLoading: false, error: e });
            });
        })
        .catch(e => {
          setState({ ...state, isLoading: false, error: e });
        });
    }
  }, [workflow]);

  const badgeColor = useMemo(() => {
    if (state.workflow.workflowStatusCode === "Pending") {
      return "#ff9800";
    } else if (state.workflow.workflowStatusCode === "Started") {
      return "#00acc1";
    } else if (state.workflow.workflowStatusCode === "Aborted") {
      return "#f44336";
    } else if (state.workflow.workflowStatusCode === "Completed") {
      return "#4caf50";
    }
    return "#000000";
  }, [state.workflow.workflowStatusCode]);

  const badgeTitle = useMemo(() => {
    return masterValues["WorkflowStatus"].find(w => w.code === state.workflow.workflowStatusCode)?.label ?? state.workflow.workflowStatusCode;
  }, [state.workflow.workflowStatusCode]);

  let dialogContent;
  if (state.error) dialogContent = <CardError error={state.error} />;
  else if (state.errorNotFound)
    dialogContent = (
      <div>
        <Trans>WF_SupplierRequest_NotFound</Trans>
      </div>
    );
  else if (!state.workflow || state.isLoading) dialogContent = <CircularProgress />;
  else if (!request) dialogContent = <div>This workflow is not complete. Please call support.</div>;
  else {
    var thirdPartyButton = <></>;
    if (
      state.exitingThirdParty?.identifier &&
      HasOneOfRights(["thirdparty_customer.view", "thirdparty_workflow.edit", "thirdparty_supplier.view", "thirdparty_workflow.view"])
    ) {
      thirdPartyButton = (
        <center>
          <Button color="info" onClick={() => dispatch(ThirdPartyActions.OpenThirdParty(state.exitingThirdParty.identifier))}>
            <Trans>ThirdParty_Open</Trans>
          </Button>
        </center>
      );
    }

    var newThirdPartyButton = <></>;
    if (
      state.newThirdParty?.identifier &&
      HasOneOfRights(["thirdparty_customer.view", "thirdparty_workflow.edit", "thirdparty_supplier.view", "thirdparty_workflow.view"])
    ) {
      newThirdPartyButton = (
        <center>
          <Button color="info" onClick={() => dispatch(ThirdPartyActions.OpenThirdParty(state.newThirdParty.identifier))}>
            <Trans>ThirdParty_Open</Trans>
          </Button>
        </center>
      );
    }

    dialogContent = (
      <div style={{ marginTop: 100, marginBottom: 100, width: "98%" }}>
        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <Card
              style={{
                ...CardInlineStyle.card
              }}
            >
              <CardHeader
                style={{
                  ...CardInlineStyle.cardHeader
                }}
                icon
              >
                <h4>
                  <Trans>Old Third Party</Trans>
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={4} sm={4} md={4}>
                    <FormLabel>
                      <Trans>Id MDM</Trans>
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <FieldText type="text" disabled value={state.exitingThirdParty?.identifier} />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    {thirdPartyButton}
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <FormLabel>
                      <Trans>Long name</Trans>
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={8} sm={8} md={8}>
                    <FieldText type="text" disabled value={state.exitingThirdParty?.longName} />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <FormLabel>
                      <Trans>Legal Identifier</Trans>
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={8} sm={8} md={8}>
                    <FieldText type="text" disabled value={state.exitingThirdParty?.legalIdentifier} />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <Card
              style={{
                ...CardInlineStyle.card
              }}
            >
              <CardHeader
                style={{
                  ...CardInlineStyle.cardHeader
                }}
                icon
              >
                <h4>
                  <Trans>New Third Party</Trans>
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={4} sm={4} md={4}>
                    <FormLabel>
                      <Trans>Id MDM</Trans>
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <FieldText type="text" disabled value={state.newThirdParty?.identifier} />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    {newThirdPartyButton}
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <FormLabel>
                      <Trans>Long name</Trans>
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={8} sm={8} md={8}>
                    <FieldText type="text" disabled value={state.newThirdParty?.longName} />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <FormLabel>
                      <Trans>Legal Identifier</Trans>
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={8} sm={8} md={8}>
                    <FieldText type="text" disabled value={state.newThirdParty?.legalIdentifier} />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={Boolean(workflow)}
        onClose={() => closeDetail(false)}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogPaper }}
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          <div>
            <span
              style={{
                border: `1px solid ${badgeColor}`,
                borderRadius: 50,
                padding: "5px 10px",
                marginRight: 15,
                backgroundColor: "#FFFFFF",
                color: badgeColor,
                fontSize: "0.8rem"
              }}
            >
              {badgeTitle}
            </span>
            {state.workflow.identifier} - {state.workflow.label}
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            ...DialogInlineStyleDetail.dialogContent
          }}
          dividers={true}
        >
          {dialogContent}
        </DialogContent>
        <DialogActions>
          {state.workflow.workflowStatusCode === "Pending" && HasRight("thirdparty_workflow.edit") && request && (
            <>
              {state.isLoading && <CircularProgress />}
              {!state.isLoading && (
                <Button color="success" onClick={validate}>
                  <Trans>Validate</Trans>
                </Button>
              )}
            </>
          )}
          {state.workflow.workflowStatusCode === "Pending" && HasRight("thirdparty_workflow.edit") && request && (
            <>
              {state.isLoading && <CircularProgress />}
              {!state.isLoading && (
                <Button color="danger" onClick={abort} disabled={state.isLoading}>
                  <Trans>WF_Abort</Trans>
                </Button>
              )}
            </>
          )}
          {state.workflow.identifier > 0 && (
            <Tooltip title={<Trans>History</Trans>} placement="bottom">
              <span>
                <Button simple color="info" onClick={() => setOpenHisto(true)}>
                  <History />
                </Button>
              </span>
            </Tooltip>
          )}
          <div style={{ flex: "1 0 0" }}></div>
          <Button onClick={() => closeDetail(true)} color={!state.hasChanged ? "info" : ""}>
            <Trans> Close </Trans>
          </Button>
        </DialogActions>
      </Dialog>
      <DialogBox dialogBox={dialogBox} setDialogBox={setDialogBox} />
      {!!openHisto && (
        <Dialog open onClose={() => setOpenHisto(false)} fullWidth={true} maxWidth="md">
          <DialogContent>
            <WorkflowHistory workflowId={state.workflow.identifier} defaultLang={defaultLang} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenHisto(false)} color="info">
              <Trans> Close </Trans>
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

const DialogInlineStyleDetail = {
  dialogContent: {
    padding: "0px 10px 0px",
    height: "95%"
  },
  dialogPaper: {
    minHeight: "90vh",
    maxHeight: "90vh"
  }
};
export default withStyles(thirdPartySearchCriteriaStyle)(InfolegaleMerge);
