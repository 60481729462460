import React, { useEffect, useState } from "react";
import { GetUsersByProfileTerritories, HasRight } from "services/user/UserHelper";

import { Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import "assets/scss/components/ThirdPartyResult.css";
import "assets/scss/components/ThirdPartySearchCriteria.css";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardError from "components/Card/CardError";

import { Trans } from "@lingui/macro";
import StructureDetailContent from "module/structure/StructureDetailContent";
import { StructureGetOwnedByWorkflow, StructureCrupdateOwnedByWorkflow } from "module/structure/actions/StructureActions";
import Button from "components/CustomButtons/Button";
import DialogBox from "components/DialogBox/DialogBox";
import { WorkflowGetHistories } from "module/workflow/store/actions";
import { sortBy } from "tools";

function CardValidators({ workflow }) {
  var [state, setState] = useState({ isLoading: false });
  if (!state.isLoading && !state.validators) {
    setState({ isLoading: true });
    GetUsersByProfileTerritories(
      workflow.validatorProfileCodes,
      workflow.validatorTerritoryCountryCode,
      workflow.validatorTerritoryErtId,
      v => setState({ isLoading: false, error: null, validators: v }),
      e => setState({ isLoading: false, error: e, validators: [], histories: [] })
    );
  }

  if (state.error) {
    return <CardError error={state.error} />;
  }

  var bodyValidators = "";
  if (state.isLoading) {
    bodyValidators = <CircularProgress />;
  } else if (state.validators) {
    bodyValidators = state.validators.map((v, k) => <Chip key={k} label={v.firstName + " " + v.lastName + " (" + v.identifier + ")"} />);
  }

  return (
    <div>
      <strong>
        <Trans>Workflow_WaitingForValidationFrom</Trans>
      </strong>
      {bodyValidators}
    </div>
  );
}

function CardHistories({ workflow }) {
  var [state, setState] = useState({ isLoading: false });
  if (!state.isLoading && !state.histories) {
    setState({ isLoading: true });
    WorkflowGetHistories(
      workflow.identifier,
      h => setState({ isLoading: false, error: null, histories: sortBy(h, "actionDate") }),
      e => setState({ isLoading: false, error: e, histories: [] })
    );
  }

  if (state.error) {
    return <CardError error={state.error} />;
  }

  var bodyComments = "";
  if (state.isLoading) {
    bodyComments = <CircularProgress />;
  } else if (state.histories && state.histories.length > 0) {
    let lastHistory = state.histories[state.histories.length - 1];
    let comment = lastHistory.actionValues.find(h => h.name === "Comment")?.valueAfter;
    bodyComments = (
      <>
        {lastHistory.actionUser} ({lastHistory.actionDate}) : {comment}
      </>
    );
  }

  return (
    <div>
      <strong>
        <Trans>Workflow_Comment</Trans>
      </strong>
      &nbsp;{bodyComments}
    </div>
  );
}

function StructureUp({ workflow, validateWorkflow, abortWorkflow, closeWorkflow, masterValues, defaultLang, classes }) {
  const [state, setState] = useState({ isLoading: true });
  const [dialogBox, setDialogBox] = useState(null);

  const getStructureByWorkflowId = workflowId => {
    setState({ isLoading: true });
    StructureGetOwnedByWorkflow(
      workflowId,
      structure => {
        setState({ ...state, isLoading: false, structure, backupStructure: JSON.stringify(structure), hasChanged: false });
      },
      _ => setState({ isLoading: false, errorNotFound: true })
    );
  };

  useEffect(() => {
    getStructureByWorkflowId(workflow.identifier);
  }, [workflow]);

  const closeDetail = confirmClose => {
    if (state.hasChanged && !confirmClose) {
      setDialogBox({ type: "yesNo", message: <Trans> ConfirmCloseWithoutSave </Trans>, yes: () => closeDetail(true) });
    } else {
      setDialogBox(null);
      closeWorkflow();
    }
  };

  const saveStructure = () => {
    setState({ ...state, isLoading: true, error: null, validationErrors: null });
    StructureCrupdateOwnedByWorkflow(
      { structure: state.structure },
      s => {
        if (!s.isSuccess) {
          var mainError = s.errors.find(e => e.severity === "Error");
          if (mainError)
            setState({
              ...state,
              isLoading: false,
              error: { message: `FR: ${mainError.descriptionFr} / EN: ${mainError.descriptionEn}` },
              validationErrors: s.errors
            });
          else setState({ ...state, isLoading: false, error: null, validationErrors: s.errors });
          return;
        }

        getStructureByWorkflowId(workflow.identifier);
      },
      e => setState({ ...state, isLoading: false, error: e, validationErrors: null }),
      e => setState({ ...state, isLoading: false, error: null, validationErrors: e })
    );
  };

  const validate = () => {
    setDialogBox({
      canDismiss: false,
      type: "okCancel",
      textbox: { title: <Trans>Comment</Trans>, rows: 2 },
      ok: text => {
        validateWorkflow({ ...workflow, validatorComment: text });
        setDialogBox(null);
      }
    });
  };

  const abort = () => {
    setDialogBox({
      canDismiss: false,
      type: "okCancel",
      textbox: { title: <Trans>Comment</Trans>, rows: 2 },
      ok: text => {
        abortWorkflow({ ...workflow, validatorComment: text });
        setDialogBox(null);
      }
    });
  };

  let dialogContent;
  if (state.error) dialogContent = <CardError error={state.error} />;
  else if (state.errorNotFound)
    dialogContent = (
      <div>
        <Trans>WF_Structure_NotFound</Trans>
      </div>
    );
  else if (state.isLoading) dialogContent = <CircularProgress />;
  else if (!state.structure) dialogContent = <CardError error={{ message: "Associated structure is not found." }} />;
  else
    dialogContent = (
      <StructureDetailContent
        isEditable={workflow.workflowStatusCode === "Pending"}
        masterValues={masterValues}
        defaultLang={defaultLang}
        structure={state.structure}
        setStructure={s => setState({ ...state, structure: s, hasChanged: JSON.stringify(s) !== state.backupStructure })}
      />
    );

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={Boolean(workflow)}
        onClose={() => closeDetail(false)}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogPaper }}
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          <div>Structure</div>
        </DialogTitle>
        <DialogContent
          style={{
            ...DialogInlineStyleDetail.dialogContent
          }}
          dividers={true}
        >
          <Card>
            <CardBody>
              {workflow.workflowStatusCode === "Pending" && <CardValidators workflow={workflow} />}
              <CardHistories workflow={workflow} />
            </CardBody>
          </Card>
          {dialogContent}
        </DialogContent>
        <DialogActions>
          {workflow.workflowStatusCode === "Pending" && HasRight("structure.approve") && (
            <Button color="success" onClick={validate}>
              <Trans>Validate</Trans>
            </Button>
          )}
          {workflow.workflowStatusCode === "Pending" && HasRight("structure.approve") && (
            <Button color="danger" onClick={abort}>
              <Trans>WF_Abort</Trans>
            </Button>
          )}
          <div style={{ flex: "1 0 0" }} />
          {state.hasChanged && (
            <Button color="info" onClick={saveStructure}>
              <Trans> Save </Trans>
            </Button>
          )}
          <Button onClick={() => closeDetail(false)} color={!state.hasChanged ? "info" : ""}>
            <Trans> Close </Trans>
          </Button>
        </DialogActions>
      </Dialog>
      <DialogBox dialogBox={dialogBox} setDialogBox={setDialogBox} />
    </>
  );
}

const DialogInlineStyleDetail = {
  dialogContent: {
    padding: "0px 10px 0px",
    height: "95%"
  },
  dialogPaper: {
    minHeight: "90vh",
    maxHeight: "90vh"
  }
};

export default withStyles(thirdPartySearchCriteriaStyle)(StructureUp);
